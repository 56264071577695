import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Flex = makeShortcode("Flex");
const Button = makeShortcode("Button");
const Image = makeShortcode("Image");
const Aligner = makeShortcode("Aligner");
const Fellows = makeShortcode("Fellows");
const Column = makeShortcode("Column");
const Process = makeShortcode("Process");
const Projects = makeShortcode("Projects");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <h1 {...{
          "id": "kernel",
          "style": {
            "position": "relative"
          }
        }}>{`Kernel`}<a parentName="h1" {...{
            "href": "#kernel",
            "aria-label": "kernel permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h1>
        <h3 {...{
          "id": "une-communauté-unique-pour-apprendre-le-web3",
          "style": {
            "position": "relative"
          }
        }}>{`Une communauté unique pour apprendre le web3`}<a parentName="h3" {...{
            "href": "#une-communaut%C3%A9-unique-pour-apprendre-le-web3",
            "aria-label": "une communauté unique pour apprendre le web3 permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h3>
        <p>{`Nous construisons un réseau ouvert, pair-à-pair, éternel d'humains exceptionels, un bloc à la fois. Chaque bloc comprend 250 individus et dure 8 semaines. Une expérience unique.`}</p>
        <p>{`Ensemble, nous renversons joyeusement le status quo.`}</p>
        <Flex sx={{
          alignItems: 'center'
        }} mdxType="Flex">
  
  <Button to='/start/' secondary inline mdxType="Button">
    Curieux ?
  </Button>
        </Flex>
        <p>{`Les candidatures sont fermées jusqu'au 22 mars.`}</p>
      </Box>
      <Image src="/images/kernel_lp.png" title="Welcome to Kernel" mdxType="Image" />
    </InfoBlock>
    <InfoBlock mdxType="InfoBlock">
      <Image src="/images/kernel_learn.png" title="Learn" mdxType="Image" />
      <Box mdxType="Box">
        <h1 {...{
          "id": "apprendre",
          "style": {
            "position": "relative"
          }
        }}>{`Apprendre`}<a parentName="h1" {...{
            "href": "#apprendre",
            "aria-label": "apprendre permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h1>
        <p>{`Que vous n'ayez aucune idée de ce qu'est une blockchain ou que vous construisiez toutes sortes de choses décentralisées depuis 2009, notre communauté vous aidera à apprendre ce que vous devez savoir pour participer à la construction d'un meilleur web, ensemble.`}</p>
        <Button to="/start/" mdxType="Button">Démarrer</Button>
      </Box>
    </InfoBlock>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <h1 {...{
          "id": "questionner",
          "style": {
            "position": "relative"
          }
        }}>{`Questionner`}<a parentName="h1" {...{
            "href": "#questionner",
            "aria-label": "questionner permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h1>
        <p>{`Bien que nous proposions 8 semaines de formation mêlant théorie et pratique, nos cours sont entièrement gratuits et peut être lus par tous à tout moment. Ils sont destinés à vous aider à vous poser de meilleures questions, plutôt qu'à fournir des réponses toutes faites.`}</p>
        <Button to="/learn/" mdxType="Button">Commencer</Button>
      </Box>
      <Image src="/images/kernel_balance.png" title="Funding" mdxType="Image" />
    </InfoBlock>
    <InfoBlock mdxType="InfoBlock">
      <Image src="/images/kernel_play.png" title="Gaming" mdxType="Image" />
      <Box mdxType="Box">
        <h1 {...{
          "id": "jouer",
          "style": {
            "position": "relative"
          }
        }}>{`Jouer`}<a parentName="h1" {...{
            "href": "#jouer",
            "aria-label": "jouer permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h1>
        <p>{`La conversation est au cœur de la façon dont nous apprenons ici, mais parfois nous avons besoin d'explorer des contextes différents, plus axés sur les compétences, avec moins de mots et plus de jeux, de construction de projets, de mouvement en pleine conscience ou d'assise tranquille. Les guildes sont là où tout cela se passe, et bien plus encore.`}</p>
        <Button to="/build" mdxType="Button">Construire</Button>
      </Box>
    </InfoBlock>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <h1 {...{
          "id": "découvrir",
          "style": {
            "position": "relative"
          }
        }}>{`Découvrir`}<a parentName="h1" {...{
            "href": "#d%C3%A9couvrir",
            "aria-label": "découvrir permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h1>
        <p>{`Notre communauté d'apprenants a contribué à la création de l'une des collections de ressources les plus vastes et les plus récentes pour les personnes qui créent de nouveaux outils et produits sur web3. Du design, à la spécification, des smartcontracts, de la sécurité et même d'outils zero-knowledge, tout est là.`}</p>
        <Button to="/resources/" mdxType="Button">Explorer</Button>
      </Box>
      <Image src="/images/kernel_work.png" title="Work With Us" mdxType="Image" />
    </InfoBlock>
    <InfoBlock sx={{
      width: '80%'
    }} mdxType="InfoBlock">
      <Flex sx={{
        flexDirection: "column"
      }} mdxType="Flex">
        <Aligner center sx={{
          marginTop: "-4rem"
        }} mdxType="Aligner">
          <h2 {...{
            "id": "membres-de-kernel",
            "style": {
              "position": "relative"
            }
          }}>{`Membres de KERNEL`}<a parentName="h2" {...{
              "href": "#membres-de-kernel",
              "aria-label": "membres de kernel permalink",
              "className": "anchor-link after"
            }}><span parentName="a">{`¶`}</span></a></h2>
        </Aligner>
      </Flex>
    </InfoBlock>
    <Aligner center mdxType="Aligner">
      <Fellows mdxType="Fellows" />
    </Aligner>
    <Aligner center mdxType="Aligner">
      <h2 {...{
        "id": "principles-de-kernel",
        "style": {
          "position": "relative"
        }
      }}>{`Principles de Kernel`}<a parentName="h2" {...{
          "href": "#principles-de-kernel",
          "aria-label": "principles de kernel permalink",
          "className": "anchor-link after"
        }}><span parentName="a">{`¶`}</span></a></h2>
    </Aligner>
    <Aligner center mdxType="Aligner">
      <Box sx={{
        width: '80%',
        textAlign: 'center'
      }} mdxType="Box">
        <p>{`En définitive, Kernel est une communauté qui se consacre au voyage vers une meilleure compréhension de la vérité : dans nos projets Web 3, dans nos relations avec les autres et dans nos mondes intérieurs.`}</p>
      </Box>
    </Aligner>
    <Box sx={{
      width: '80%',
      margin: '0 auto'
    }} mdxType="Box">
      <Column mdxType="Column">
        <Box mdxType="Box">
          <h3 {...{
            "id": "️-principes-du-web-3",
            "style": {
              "position": "relative"
            }
          }}>{`⌛️ Principes du Web 3`}<a parentName="h3" {...{
              "href": "#%EF%B8%8F-principes-du-web-3",
              "aria-label": "️ principes du web 3 permalink",
              "className": "anchor-link after"
            }}><span parentName="a">{`¶`}</span></a></h3>
          <Process mdxType="Process">
            <p>{`La confiance n'est pas seulement transactionnelle.`}</p>
            <p>{`Les vérités partagées créent de la valeur.`}</p>
            <p>{`Apprenez les limites. vous serez alors libre.`}</p>
            <p>{`L'argent peut parler.`}</p>
            <p>{`Les motivations expliquent tout.`}</p>
            <p>{`Libérez les institutions radicales.`}</p>
            <p>{`Résistez économiquement à la censure.`}</p>
            <p>{`La capacité de mise à l'échelle.`}</p>
          </Process>
        </Box>
        <Box mdxType="Box">
          <h3 {...{
            "id": "-principes-personels",
            "style": {
              "position": "relative"
            }
          }}>{`🌈 Principes Personels`}<a parentName="h3" {...{
              "href": "#-principes-personels",
              "aria-label": " principes personels permalink",
              "className": "anchor-link after"
            }}><span parentName="a">{`¶`}</span></a></h3>
          <Process mdxType="Process">
            <p>{`Jouer avec les modèles.`}</p>
            <p>{`Développez les moyens de signifier.`}</p>
            <p>{`Posez de meilleures questions.`}</p>
            <p>{`Considérez d'abord votre volonté.`}</p>
            <p>{`Écoutez et racontez de meilleures histoires.`}</p>
            <p>{`Les individus gouvernent bien ensemble.`}</p>
            <p>{`Apprenez à aimer apprendre.`}</p>
            <p>{`Le don est sacré.`}</p>
          </Process>
        </Box>
      </Column>
    </Box>
    <InfoBlock sx={{
      width: '80%'
    }} mdxType="InfoBlock">
      <Flex sx={{
        flexDirection: "column"
      }} mdxType="Flex">
        <Aligner center sx={{
          marginTop: "-4rem"
        }} mdxType="Aligner">
          <h2 {...{
            "id": "projets--équipes",
            "style": {
              "position": "relative"
            }
          }}>{`Projets & Équipes`}<a parentName="h2" {...{
              "href": "#projets--%C3%A9quipes",
              "aria-label": "projets  équipes permalink",
              "className": "anchor-link after"
            }}><span parentName="a">{`¶`}</span></a></h2>
        </Aligner>
      </Flex>
    </InfoBlock>
    <Aligner center mdxType="Aligner">
      <Projects mdxType="Projects" />
    </Aligner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      